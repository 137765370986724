@import "src/scss/variables";

.confirm-email {
  position: absolute;
  left: 80px;
  right: 80px;
  top: 50%;
  transform: translate(0%, -50%);
  display: flex;
  flex-direction: column;

  &-header {
    height: 160px;
    display: flex;
    align-items: center;
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
  }

  .text-16 {
    margin-top: 8px;
    color: $color-gray;
  }

  &-send {
    width: 118px;
  }

  &-log-out {
    width: 118px;
    margin-left: 16px;
  }
}
.my-streams-create-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
  min-height: 160px;
}

.my-streams-create-container-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
  min-height: 100px;
}

.infobox-container {
  display: flex;
  min-height: 72px;
  width: 504px;
  margin-left: 80px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #e6e6e6;
}

.infobox-container-mobile {
  display: flex;
  min-height: 72px;
  width: 80%;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #e6e6e6;
  margin-left: 5%;
  margin-right: 5%;
  padding-left: 5%;
  padding-right: 5%;
}

.create-stream-info {
  display: flex;
  width: 620px;
  margin-left: 80px;
  margin-top: 40px;
  flex-direction: row;
  align-items: flex-end;
  min-height: 80px;
}

.create-stream-info-mobile {
  display: flex;
  margin-left: 5%;
  width: 90%;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  min-height: 80px;
}

.create-stream-divider-container {
  height: 40px;
  align-items: center;
}

.create-stream-divider {
  height: 1px;
  background: black;
  opacity: 0.08;
}

.create-stream-social-titile-container {
  display: flex;
  height: 88px;
  align-items: center;
}

.create-stream-social-container {
  display: flex;
  width: 620px;
  height: 48px;
  justify-content: space-between;
}

.title24Bold {
  font-size: 24px;
  font-weight: bold;
  color: black;
}

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 360px;
  border-radius: 180px;
  width: 100%;
  background-color: white;
  border: 1px dashed #e6e6e6;
}

.close-tag-btn {
  align-self: center;
  align-items: center;
  appearance: none;
  background: red;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  padding: 0;
  width: 15px;
}

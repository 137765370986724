.container {
  display: flex;
  flex-direction: column;
}

label {
  display: block;
  color: #999999;
  font-size: 16px;
  float: left;
}

input {
  margin-top: 8px;
  height: 48px;
  font-size: 16px;
  padding-left: 16px;
  color: black;
  background-color: white;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  font-family: AvenirNext-Medium;
}

textarea:hover,
input:hover {
  border: 1px solid #d3d3d3;
}

textarea:focus,
input:focus {
  border: 1px solid #0058dc;
}

textarea {
  margin-top: 8px;
  height: 48px;
  font-size: 16px;
  padding-left: 16px;
  color: black;
  background-color: white;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  font-family: AvenirNext-Medium;
}

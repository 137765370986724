.container {
  display: flex;
  flex-direction: column;
  background-color: "#FAFAFA";
}

.logo-container {
  height: 160px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
}
.logo-container-mobile {
  height: 100px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
}
.auth-logo-image {
  width: 240px;
  height: 40px;
  display: flex;
  align-self: center;
}

.form-container {
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.form-container-mobile {
  display: flex;
  margin-bottom: 80px;
  justify-content: center;
  align-content: center;
}
.form {
  width: 400px;
  display: flex;
  flex-direction: column;
}
.formMobile {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.inputMargin {
  margin-top: 40px;
}

.buttons-container {
  margin-top: 40px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.buttons-container-mobile {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.divider-container {
  display: flex;
  margin-top: 40px;
  height: 24px;
  align-items: center;
  justify-content: space-between;
}

.line {
  width: 40%;
  height: 1px;
  background: black;
  opacity: 0.08;
}

.login-container {
  width: 400px;
  display: flex;
  margin-top: 176px;
  margin-bottom: 64px;
  height: 24px;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}

.login-container-mobile {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 64px;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.signup-container-login-btn {
  width: 160px;
}

.signup-container-login-btn-mobile {
  width: 100%;
  margin-top: 40px;
}

.signup-container-label {
  color: black;
  align-self: center;
}

.signup-container-label-mobile {
  color: black;
  align-self: center;
  margin: 0 auto;
}
.login-container-label {
  align-self: center;
  cursor: pointer;
}

.login-container-label-mobile {
  cursor: pointer;
  margin-bottom: 24px;
}

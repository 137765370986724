.header {
  display: flex;
  height: 80px;
  padding-left: 80px;
  padding-right: 80px;
  justify-content: space-between;
  border-bottom: 0.5px solid #ededed;
}

.header-mobile {
  display: flex;
  height: 80px;
  padding-left: 5%;
  padding-right: 5%;
  justify-content: space-between;
  border-bottom: 0.5px solid #ededed;
}

.logo-image {
  height: 24px;
  width: 144px;
  display: flex;
  align-self: center;
}

.avatar-image {
  color: gray;
  height: 24px;
  width: 24px;
  margin-right: 16px;
}

#dropdown-basic-button {
  border-width: 0px;
  background-color: transparent;
  color: black;
}

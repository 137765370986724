.segmented-control.my-segment-control {
  color: transparent;
  border: 0px;
  background: #e0e9f6;
  border-radius: 4px;
  font-weight: 600;
}

.segmented-control.my-segment-control > label {
  color: #0058dc;
}

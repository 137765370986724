@font-face {
  font-family: 'AvenirNext-Medium';
  src: url('../assets/fonts/AvenirNext-Medium.ttf')
}

@font-face {
  font-family: 'AvenirNext-DemiBold';
  src: url('../assets/fonts/AvenirNext-DemiBold.ttf')
}

@font-face {
  font-family: 'AvenirNext-Bold';
  src: url('../assets/fonts/AvenirNext-Bold.ttf')
}
.text-24-demi-bold {
  font: 24px/32px $font-demibold;
  color: $color-black;
}

.text-40-bold {
  font: 40px/56px $font-bold;
  color: $color-black;
}

.text-24-bold {
  font: 24px/32px $font-bold;
  color: $color-black;
}

.text-16-bold {
  font: 16px/24px $font-bold;
  color: $color-black;
}

.text-24 {
  font: 24px/32px $font-medium;
  color: $color-black;
}

.text-16 {
  font: 16px/24px $font-medium;
  color: $color-black;
}

.text-12 {
  font: 12px/16px $font-medium;
  color: $color-black;
}
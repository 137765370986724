.my-create-streams-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
  height: 160px;
}

.my-create-streams-container-mobile {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: left;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 24px;
}

.my-streams-container {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  padding-left: 80px;
  padding-right: 80px;
  height: 64px;
}

.horizontal-offset-mobile {
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 24px;
}

.horizontal-offset {
  margin-top: 24px;
  padding-left: 80px;
  padding-right: 80px;
  height: 64px;
}
.my-streams-container-mobile {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 40px;
  padding-left: 5%;
  padding-right: 5%;
}

.title40bold {
  font-size: 40px;
  font-weight: bold;
  color: black;
}

.text24bold {
  font-size: 24px;
  font-weight: bold;
  color: black;
}

.text16 {
  font-size: 16px;
  color: #999999;
}

.list-manageBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  cursor: pointer;
}

.list-container {
  display: flex;
  height: 72px;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
}
.list-container:hover {
  background: #f5f5f5;
}

.list-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

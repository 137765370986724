@import "./scss/variables";
@import "./scss/fonts";

.screen {
  height: 100%;
  width: 82%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

label{
  text-overflow: ellipsis;
}

.clickable:hover {
  box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.04);
}

a {
  cursor: pointer;
  color: $color-blue;
  text-decoration: none;
  font: 16px/24px $font-demibold;

  &:hover{
    color: #0047B3;
  }
}